import { useEffect, useState } from "react";
import LandingDescription from "./Description"
import ProjectsContainer from "./ProjectsContainer"
import TokenList from "./TokenList"
import Toolbar from "./Toolbar";

const Landing = () => {
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState(null);
  const [status, setStatus] = useState(true);

  const SortingTypes = [
    { name: "Global rank", type: "ranking" },
    { name: "Most members", type: "member" },
    { name: "Most burned", type: "burned" },
    { name: "Most actions", type: "actions" },
  ];

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  }
  const handleSortChange = (index) => {
    setSortType(index);
  }
  const handleStatusChange = (s) => {
    setStatus(s);
  };

  return (
    <div
      className=" w-full text-white flex flex-col gap-[24px]"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/landing_bg.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}>
      <LandingDescription selectedProjectIndex={selectedProjectIndex} />
      <TokenList selectedProjectIndex={selectedProjectIndex} setSelectedProjectIndex={setSelectedProjectIndex} />
      <Toolbar 
        onSearchChange={handleSearchChange}
        onSortChange={handleSortChange}
        onStatusChange={handleStatusChange}
        SortingTypes={SortingTypes} />
      <ProjectsContainer 
        searchQuery={searchQuery}
        sortType={sortType}
        status={status}
        SortingTypes={SortingTypes} />
    </div>)
}

export default Landing
