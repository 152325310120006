import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import Board from './Board';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const BurntTokenChart = () => {
  const data = {
    labels: [
      '8/1', '8/2', '8/3', '8/4', '8/5', '8/6',
      '8/7', '8/8', '8/9', '8/10', '8/11', '8/12', '8/13', '8/14'
    ],
    datasets: [
      {
        label: 'Burn',
        data: [12000, 15000, 13000, 18000, 16000, 17000, 17000, 17000, 18000, 20000, 21000, 21000, 22000, 23000], // Counts
        borderColor: '#D6983E',
        backgroundColor: '#D6983E',
        fill: false,
        stepped: true,
        yAxisID: 'y2',
        pointRadius: 3,
      },
      {
        label: 'Price',
        data: [1.2, 1.5, 1.3, 1.8, 1.6, 1.7, 1.7, 1.7, 1.8, 2.0, 2.1, 2.1, 2.2, 2.3], // Prices
        borderColor: '#9C56B7',
        backgroundColor: 'rgba(200,98,248,0.1)',
        fill: true,
        tension: 0.4,
        yAxisID: 'y1',
        pointRadius: 3,
      },

    ],
  };

  const options = {
    scales: {
      x: {
        ticks: {
          color: '#56B0B9'
        },
        grid: {
          display: false,
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks: {
          color: '#9C56B7'
        },
        grid: {
          display: true,
          color: '#56B0B9',
        },
        border: {
          display: false
        }
      },
      y2: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          color: '#D6983E',
          callback: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value;
          },
        },
        grid: {
          drawOnChartArea: false,
        },
        border: {
          display: false
        },
        label: {
          fillStyle: "#D6983E",
          hidden: true,
        }
      },
    },
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          borderWidth: 0
        }
      },
    },
    
  };

  return (
    <Board className="p-6">
      <div className="flex items-center gap-4">
        <img src={`${process.env.PUBLIC_URL}/assets/icons/burn-chart.svg`}
          alt="burn-chart" className="w-9 h-9" />
        <span className="font-bold text-[22px]">price vs. Burn chart</span>
      </div>
      <Line data={data} options={options} />
    </Board>
  );
};

export default BurntTokenChart;
