// src/hooks/useCreateProjectMutation.js

import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../utils/axiosInstance";

const useCreateProjectMutation = () => {
  return useMutation({
    mutationFn: async (data) => {
      const formData = new FormData();
      formData.append("projectName", data.projectName);
      formData.append("chainId", data.chainId);
      formData.append("tokenAddress", data.tokenAddress);
      formData.append("description", data.description);
      formData.append("interactionHashtag", data.interactionHashtag);
      formData.append("officialWebsite", data.officialWebsite);
      formData.append("owner", data.owner);
      formData.append("projectSocials[twitter]", data.projectSocials.twitter);
      formData.append("projectSocials[telegram]", data.projectSocials.telegram);
      formData.append("tokenSymbol", data.tokenSymbol);
      if (data.avatar) {
        formData.append("avatar", data.avatar);
      }
      if (data.socialImage) {
        formData.append("socialImage", data.socialImage);
      }

      const response = await axiosInstance.post(`/projects`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    },
    onSuccess: (data) => {
      // Handle success
    },
    onError: (error) => {
      // Handle error
    },
  });
};

export default useCreateProjectMutation;
