import React, {useState } from "react";
import Board from "../../../components/Board";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import SetProjectBUrnThresholdInput from "../../../components/SetProjectBurnThresholdInput";

const SetBurnThresholds = () => {
  const navigation = useNavigate();
  const [like, setLike] = useState(0);
  const [likeBurn, setLikeBurn] = useState(0);
  const [share, setShare] = useState(0);
  const [shareBurn, setShareBurn] = useState(0);
  const [comment, setComment] = useState(0);
  const [commentBurn, setCommentBurn] = useState(0);
  const [retweet, setRetweet] = useState(0);
  const [retweetBurn, setRetweetBurn] = useState(0);
  const [takeover, setTakeover] = useState(0);
  const [takeoverBurn, setTakeoverBurn] = useState(0);

  return (
    <div
      className="flex items-center justify-center w-full pt-10 text-white "
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/admin_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="lg:w-[52%] md:w-[56%] sm:w-[60%] w-[80%] md:mt-[124px] mt-[80px]">
        <Board>
          <div className="p-6">
            <div className="flex py-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/set_project.svg`}
                alt="Burn"
              />
              <span className="pl-4 text-[22px]">
                Set project burn thresholds{" "}
              </span>
            </div>

            <div className="text-[12px] px-1.5 sm:py-4 py-2">
              <label className="text-[#56B0B9] ">T W I T T E R</label>
              <div className="flex flex-col">
                <div className="flex sm:flex-row flex-col bg-[#000000] bg-opacity-30 rounded-[8px] p-1 sm:items-center items-baseline justify-between my-1">
                  <div className="flex pl-3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
                      alt="twitter"
                    />
                    <span className="text-[16px] ml-3">Likes</span>
                  </div>
                  <div className="bg-[#000000] bg-opacity-60 rounded-[8px] p-4 sm:w-[60%] w-full">
                    <span className="pr-1">Every</span>
                    <SetProjectBUrnThresholdInput val={like} onChange={(e) => {
                      setLike(e.target.value);
                    }} />
                    <span className="px-1">Likes,</span>
                    <span className="text-[#FF8A00] px-1"> burn </span>
                    <SetProjectBUrnThresholdInput val={likeBurn} onChange={(e) => {
                      setLikeBurn(e.target.value);
                    }} />
                    <span className="pl-1">tokens</span>
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col bg-[#000000] bg-opacity-30 rounded-[8px] p-1 sm:items-center items-baseline justify-between my-1">
                  <div className="flex pl-3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
                      alt="twitter"
                    />
                    <span className="text-[16px] ml-3">Shares</span>
                  </div>
                  <div className="bg-[#000000] bg-opacity-60 rounded-[8px] p-4 sm:w-[60%] w-full">
                    <span className="pr-1">Every</span>
                    <SetProjectBUrnThresholdInput val={share} onChange={(e)=>{
                      setShare(e.target.value);
                    }} />
                    <span className="px-1">Shares,</span>
                    <span className="text-[#FF8A00] px-1"> burn </span>
                    <SetProjectBUrnThresholdInput val={shareBurn} onChange={(e)=>{
                      setShareBurn(e.target.value);
                    }} />
                    <span className="pl-1">tokens</span>
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col bg-[#000000] bg-opacity-30 rounded-[8px] p-1 sm:items-center items-baseline justify-between my-1">
                  <div className="flex pl-3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
                      alt="twitter"
                    />
                    <span className="text-[16px] ml-3">Comments</span>
                  </div>
                  <div className="bg-[#000000] bg-opacity-60 rounded-[8px] p-4 sm:w-[60%] w-full">
                    <span className="pr-1">Every</span>
                    <SetProjectBUrnThresholdInput val={comment} onChange={(e)=>{
                      setComment(e.target.value);
                    }} />
                    <span className="px-1">Comments,</span>
                    <span className="text-[#FF8A00] px-1"> burn </span>
                    <SetProjectBUrnThresholdInput val={commentBurn} onChange={(e)=>{
                      setCommentBurn(e.target.value);
                    }} />
                    <span className="pl-1">tokens</span>
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col bg-[#000000] bg-opacity-30 rounded-[8px] p-1 sm:items-center items-baseline justify-between my-1">
                  <div className="flex pl-3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
                      alt="twitter"
                    />
                    <span className="text-[16px] ml-3">Retweets</span>
                  </div>
                  <div className="bg-[#000000] bg-opacity-60 rounded-[8px] p-4 sm:w-[60%] w-full">
                    <span className="pr-1">Every</span>
                    <SetProjectBUrnThresholdInput val={retweet} onChange={(e)=>{
                      setRetweet(e.target.value);
                    }} />
                    <span className="px-1">Retweets,</span>
                    <span className="text-[#FF8A00] px-1"> burn </span>
                    <SetProjectBUrnThresholdInput val={retweetBurn} onChange={(e)=>{
                      setRetweetBurn(e.target.value);
                    }} />
                    <span className="pl-1">tokens</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-[12px] px-1.5 sm:py-4 py-2">
              <label className="text-[#56B0B9] ">T O K E N T A K O V E R</label>
              <div className="flex flex-col">
                <div className="flex sm:flex-row flex-col bg-[#000000] bg-opacity-30 rounded-[8px] p-1 sm:items-center items-baseline justify-between my-1">
                  <div className="flex pl-3">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/logo_icon.svg`}
                      alt="twitter"
                      width="12%"
                      height="12%"
                    />
                    <span className="text-[16px] ml-3">
                      % of Holders Joined
                    </span>
                  </div>
                  <div className="bg-[#000000] bg-opacity-60 rounded-[8px] p-4 sm:w-[60%] w-full">
                    <span className="pr-1">Once</span>
                    <SetProjectBUrnThresholdInput val={takeover} onChange={(e)=>{
                      setTakeover(e.target.value);
                    }} />
                    <span className="px-1">
                      % of holders have joined the <br /> Takeover,
                    </span>
                    <span className="text-[#FF8A00] px-1"> burn </span>
                    <SetProjectBUrnThresholdInput val={takeoverBurn} onChange={(e)=>{
                      setTakeoverBurn(e.target.value);
                    }} />
                    <span className="pl-1">tokens</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-auto">
              <div className="flex justify-between mt-4">
                <Button width="fit" label="Manage Thresholds"></Button>
                <Button
                  width="fit"
                  label="Next"
                  onClick={() => {
                    navigation("/takeover/burn/burn_success");
                  }}
                />
              </div>
            </div>
          </div>
        </Board>
      </div>
    </div>
  );
};

export default SetBurnThresholds;
