import React from "react";
import TokensBurned from "./TokensBurned";
import SocialActions from "./SocialActions";
import TransactionTable from "./TransactionTable";
import BurntTokenChart from "../../../components/BurntTokenChart";


const ProjectDetailContent = () => {
  return (<div className="md:w-[60%] w-11/12 flex flex-col gap-6">
    <TokensBurned />
    <SocialActions />
    <BurntTokenChart />
    <TransactionTable />
  </div>
  )
}

export default ProjectDetailContent