import React from "react";
import Board from "../../../../components/Board";
import Button from "../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useProjectById } from "../../../../hooks/useProjectById";

const DoesNotToken = () => {
  const navigation = useNavigate();
  const { disconnect } = useDisconnect();
  const { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
  } = useProjectById({ _id: projectId });
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading project.</p>;
  return (
    <Board className="min-h-screen md:w-[60%] w-11/12 flex flex-col">
      <div className="p-6">
        <div className="flex py-4">
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/choose_wallet.svg`}
            alt="choose_wallet"
          />
          <span className="pl-4 text-[22px]">Choose a wallet provider</span>
        </div>

        <div className="flex flex-col gap-6 px-4 py-3">
          <div className="flex flex-col bg-[#F83737] bg-opacity-[32%] rounded-lg text-center p-4 mt-3 mb-2">
            <span className="text-[#FFD3D3] text-[18px] leading-8 tracking-tighter">
              The chosen wallet is not currently holding any $
              {project.tokenSymbol}
            </span>
            <span className="text-[#FFD3D3] text-[18px] leading-8 tracking-tighter">
              Please choose a different wallet.
            </span>
          </div>

          <div className="flex justify-center gap-6">
            <Button
              label="Try another wallet"
              width="fit"
              onClick={async () => {
                await disconnect();
                navigation(`/details/${projectId}/connect_wallet`);
              }}
            />
          </div>
        </div>
      </div>
    </Board>
  );
};

export default DoesNotToken;
