import React from "react";
import Board from "../../../../components/Board";
import WalletList from "./WalletList";
import { useProjectById } from "../../../../hooks/useProjectById";
import { useParams } from "react-router-dom";

const ProjectDetailConnectWallet = () => {
  const { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
  } = useProjectById({ _id: projectId });
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading project.</p>;
  return (
    <div className="md:w-[60%] w-11/12 flex flex-col gap-6">
      <Board>
        <div className="p-6">
          <div className="flex py-4">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/choose_wallet.svg`}
              alt="choose_wallet"
            />
            <span className="pl-4 text-[22px]">Choose a wallet provider</span>
          </div>
          <WalletList selected_project={project} />
        </div>
      </Board>
    </div>
  );
};

export default ProjectDetailConnectWallet;
