import React from "react";
import Board from "../../components/Board";
import Button from "../../components/Button";

const ChooseWallet = () => {


    return (
        <div
            className=" w-full h-screen text-white pt-10 flex justify-center items-center"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/admin_bg.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
            <div className="md:w-[48%] w-[95%] ">
                <Board>
                    <div className="p-6">
                        <div className="flex py-4">
                            <img src={`${process.env.PUBLIC_URL}/assets/icons/choose_wallet.svg`} alt="choose_wallet" />
                            <span className="pl-4 text-[22px]">Choose a wallet provider</span>
                        </div>

                        <div className="flex flex-col justify-center py-12 px-4 gap-7">
                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5" onClick={()=>{
                                    window.location.href = "selectproject"
                                }}>
                                    <span>MetaMask</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/MetaMask - jpeg.png`} alt="metamask" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Coinbase</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Coinbase.png`} alt="coinbase" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Phantom</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Phantom.png`} alt="phantom" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Trust Wallet</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Trust_wallet.png`} alt="trust" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Exodus</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Exodus.png`} alt="exodus" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Argent</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Argent - jpeg.png`} alt="argent" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Rainbow</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Rainbow - png.png`} alt="rainbow" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Blue Wallet</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Blue_wallet.png`} alt="blue" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Trezor</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Trezor.png`} alt="trezor" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Keep Key</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/keep_key.png`} alt="keep key" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center gap-7 w-full">
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Ledger</span>
                                    <div className="flex gap-5">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Ledger.png`} alt="ledger" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center w-[35%] cursor-pointer bg-[#000] bg-opacity-10 rounded-[8px] px-3 py-1.5">
                                    <span>Gnosis</span>
                                    <div className="flex gap-4">
                                        <img className="rounded" src={`${process.env.PUBLIC_URL}/assets/icons/Gnosis Safe - jpeg.png`} alt="gnosis" />
                                        <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Board>
            </div>
        </div>
    )
}

export default ChooseWallet;