import React from "react";
import Sidebar from "./Sidebar";
import AdminProjectDetailContent from "./Content";
import { useProjectById } from "../../../hooks/useProjectById";
import { useParams } from "react-router-dom";

const AdminProjectDetail = () => {
  const { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
  } = useProjectById({ _id: projectId });

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading project.</p>;
  return (
    <div
      className="flex flex-col w-full text-white "
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/takeover-detail_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top left",
      }}
    >
      <div className="w-full pt-[36px] flex md:gap-0 md:flex-row flex-col md:mt-[124px] mt-[80px] px-[48px] max-w-[1358px] justify-between items-start mx-auto">
        <Sidebar selectedProject={project} />
        <AdminProjectDetailContent />
      </div>
    </div>
  );
};

export default AdminProjectDetail;
