import React from "react";
import Board from "../../components/Board";
import Button from "../../components/Button";

const SetProject = () => {


    return (
        <div
            className=" w-full h-screen text-white pt-10 flex justify-center items-center"
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/admin_bg.png)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
            <div className="md:w-[52%] w-[95%] ">
                <Board>
                    <div className="p-6">
                        <div className="flex py-4">
                            <img src={`${process.env.PUBLIC_URL}/assets/icons/set_project.svg`} alt="success" />
                            <span className="pl-4 text-[22px]">Set the project metadata</span>
                        </div>

                        <div className="overflow-y-auto flex flex-col">
                            <div className="pb-5 border-b border-[#56B0B9]">
                                <div className="text-[14px] flex justify-between items-center py-[9px]">
                                    <label className="">Project name</label>
                                    <input className="bg-[#111827] px-[13px] py-[9px] border border-[#047857] rounded-md" placeholder="your project name" />
                                </div>
                            </div>
                            <div className="py-5 border-b border-[#56B0B9]">
                                <div className="text-[14px] flex justify-between items-center py-[9px]">
                                    <label className="">Token symbol</label>
                                    <input className="bg-[#111827] px-[13px] py-[9px] border border-[#047857] rounded-md" placeholder="your token" />
                                </div>
                            </div>
                            <div className="py-5 border-b border-[#56B0B9]">
                                <div className="text-[14px] flex justify-between items-center py-[9px]">
                                    <label className="">Token symbol</label>
                                    <input className="bg-[#111827] px-[13px] py-[9px] border border-[#047857] rounded-md" placeholder="your token" />
                                </div>
                            </div>
                            <div className="py-5 border-b border-[#56B0B9]">
                                <div className="text-[14px] flex justify-between items-center py-[9px]">
                                    <label className="">Token symbol</label>
                                    <input className="bg-[#111827] px-[13px] py-[9px] border border-[#047857] rounded-md" placeholder="your token" />
                                </div>
                            </div>
                            <div className="py-5">
                                <div className="text-[14px] flex justify-between items-center py-[9px]">
                                    <label className="">Token symbol</label>
                                    <input className="bg-[#111827] px-[13px] py-[9px] border border-[#047857] rounded-md" placeholder="your token" />
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end">
                            <Button width='fit' label="Project dashboard"></Button>
                        </div>
                    </div>
                </Board>
            </div>
        </div>
    )
}

export default SetProject;