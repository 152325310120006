import React from "react";
import Board from "../../../../components/Board";
import Button from "../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";

const BurnConfirmation = () => {
  const navigation = useNavigate();
  const { projectId } = useParams();
  return (
    <div className="md:w-[60%] w-11/12 flex flex-col gap-6">
      <Board className="min-h-screen">
        <div className="p-2 sm:p-6">
          <div className="flex py-4">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/solar_fire-bold.svg`}
              alt="solar_fire-bold"
            />
            <span className="pl-4 text-[22px]">Burn baby burn</span>
          </div>

          <div className="flex flex-col gap-2 px-4 py-3">
            <div className="flex flex-col bg-[#F83737] bg-opacity-[32%] rounded-lg text-center p-4 mt-3 mb-2">
              <span className="text-[#FFD3D3] text-[18px] leading-8 tracking-tighter">
                You are about to commit 5% <b>(20,000 $KATT)</b> of your current
                holding in this wallet.{" "}
              </span>
              <span className="text-[#FFD3D3] text-[18px] leading-8 tracking-tighter">
                This action is irreversible and the tokens will be immediately
                transferred.
              </span>
            </div>

            <span className="text-[16px] leading-6 tracking-tighter">
              By clicking Add tokens to burn pile below you acknowledge that you
              have read and understand the above warning and wish to proceed
              with this action.
            </span>

            <div className="flex flex-col gap-3 mt-6">
              <div className="flex justify-center">
                <Button
                  label="Add tokens to burn pile"
                  width="fit"
                  onClick={() => {
                    navigation(`/details/${projectId}`);
                  }}
                />
              </div>
              <span
                className="text-[#00ff91] text-[12px] text-center py-[9px]"
                onClick={() => {}}
              >
                <span className="border-b border-[#00ff91] leading-6 text-sm cursor-pointer">
                  Back
                </span>
              </span>
            </div>
          </div>
        </div>
      </Board>
    </div>
  );
};

export default BurnConfirmation;
