import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { WalletOptions } from "../../../../wallet-option";
import { useAccount } from "wagmi";
import { useWalletTokenBalance } from "../../../../hooks/useWalletTokenBalance";
import { toast } from "react-toastify";
import useStore from "../../../../store/useStore";

const WalletList = ({ selected_project }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const joinTakeOver = useStore((state) => state.setJoined);
  const setJoinedAddress = useStore((state) => state.setJoinedAddress);
  const { chainId, isConnected, address } = useAccount();
  const { data: tokens, isLoading } = useWalletTokenBalance(
    address,
    "0x" + chainId?.toString(16),
    selected_project.tokenAddress
  );

  useEffect(() => {
    if (
      isConnected &&
      tokens !== undefined &&
      tokens !== null &&
      tokens.length &&
      tokens.length !== undefined &&
      tokens.length > 0
    ) {
      joinTakeOver(true);
      setJoinedAddress(tokens[0].tokenAddress);
      toast.success("Joined!");
      navigate(`/details/${projectId}/burn_baby_burn`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, tokens]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="flex flex-wrap justify-center px-4 py-12 gap-7">
      <WalletOptions
        nextUrl={
          isConnected &&
          tokens !== undefined &&
          tokens.length !== null &&
          tokens.length !== undefined &&
          tokens.length === 0
            ? `/details/${projectId}/dosent_have_token`
            : null
        }
      />
    </div>
  );
};

export default WalletList;
