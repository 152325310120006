import React, { useEffect, useState } from "react";
import Board from "../../../components/Board";
import Button from "../../../components/Button";
import TokenList from "./TokenList";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { useWalletOwnedAllTokens } from "../../../hooks/useWalletOwnedAllTokens";
import useStore from "../../../store/useStore";

const SelectToken = () => {
  const navigation = useNavigate();
  const [checkOn, setCheckOn] = useState(false);
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(null);
  const { chainId, isConnected, address } = useAccount();
  const updateProjectInfoField = useStore(
    (state) => state.updateProjectInfoField
  );

  useEffect(() => {
    if (!isConnected) navigation("/takeover");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const {
    data: tokens,
    isLoading,
    isError,
  } = useWalletOwnedAllTokens(address, "0x" + chainId?.toString(16));
  useEffect(() => {
    if (selectedTokenIndex !== null) {
      updateProjectInfoField(
        "tokenAddress",
        tokens[selectedTokenIndex].token_address
      );
      updateProjectInfoField("tokenSymbol", tokens[selectedTokenIndex].symbol);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTokenIndex]);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading tokens.</p>;

  return (
    <div
      className="flex items-center justify-center w-full pt-10 text-white"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/admin_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="mt-[80px] w-[80%] sm:w-[60%] md:mt-[124px] md:w-[56%] lg:w-[52%]">
        <Board className="h-[calc(100vh-300px)] overflow-auto">
          <div className="flex flex-col justify-between h-full p-6">
            <div className="flex flex-col justify-between">
              <div className="flex py-4">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/select_project.svg`}
                  alt="select_token"
                />
                <span className="pl-4 text-[22px]">Select a Token you own</span>
              </div>
              <TokenList
                selectedTokenIndex={selectedTokenIndex}
                setSelectedTokenIndex={setSelectedTokenIndex}
                tokens={tokens}
              />
            </div>
            <div>
              <div
                className="flex cursor-pointer justify-between rounded-[8px] bg-[#000] bg-opacity-30 py-3 pl-3 pr-4"
                onClick={() => {
                  setCheckOn(!checkOn);
                }}
              >
                <span className="text-[16px] select-none">
                  I certify that I am one of the principal owners of the above
                  selected token
                </span>

                {/* CheckBox show */}
                {!checkOn ? (
                  <div>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/check-off.svg`}
                        alt="checkoff"
                      />
                    </span>
                  </div>
                ) : (
                  <div>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/check-on.svg`}
                        alt="checkon"
                      />
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  width="fit"
                  label="Next"
                  disabled={!checkOn || selectedTokenIndex === null}
                  onClick={() => {
                    if (checkOn && selectedTokenIndex !== null)
                      navigation("/takeover/set_project");
                  }}
                />
              </div>
            </div>
          </div>
        </Board>
      </div>
    </div>
  );
};

export default SelectToken;
