import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import { Outlet, useParams } from "react-router-dom";
import { useProjectById } from "../../hooks/useProjectById";
import useStore from "../../store/useStore";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
  } = useProjectById({ _id: projectId });
  const joinTakeOver = useStore((state) => state.setJoined);
  const setJoinedAddress = useStore((state) => state.setJoinedAddress);

  useEffect(() => {
    joinTakeOver(false);
    setJoinedAddress(null);
  }, [projectId]);

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading project.</p>;

  return (
    <div
      className=" w-full text-white flex flex-col"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/takeover-detail_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top left",
      }}
    >
      <div className="w-full pt-[36px] flex md:gap-0 md:flex-row flex-col md:mt-[124px] mt-[80px] sm:px-[48px] px-4 max-w-[1358px] justify-between  md:mx-auto mx-0 md:items-start items-center">
        <Sidebar selectedProject={project} />
        <Outlet />
      </div>
    </div>
  );
};

export default ProjectDetail;
