import React, { useState, useEffect } from "react";
import Board from "../../../components/Board";
import Button from "../../../components/Button";
import ProgressSlider from "../../../components/ProgressSlider";
import { useNavigate } from "react-router-dom";

const StartBurnPile = () => {
  const navigation = useNavigate();
  const [checkOn, setCheckOn] = useState(false);
  const [sliderOn, setSliderOn] = useState(false);
  const [amount, setAmount] = useState(20000);
  const [percentage, setPercentage] = useState(5);

  return (
    <div
      className="flex items-center justify-center w-full pt-10 text-white"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/admin_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="mt-[80px] w-[80%] sm:w-[60%] md:mt-[124px] md:w-[56%] lg:w-[52%]">
        <Board>
          <div className="p-6">
            <div className="flex py-4">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/burn.svg`}
                alt="Burn"
              />
              <span className="pl-4 text-[22px]">Start the burn pile</span>
            </div>

            <div className="cursor-pointer px-1.5 py-4 text-[16px]">
              <p>
                Use the slider below or click{" "}
                <span className="text-[#00FF91]">Enter exact amount</span> to
                decide how many tokens you want to commit to the burn pile to
                get your takeover started. Committed tokens cannot be recovered.
              </p>
            </div>

            <div className="flex flex-col px-1.5 py-[15px]">
              {!sliderOn ? (
                <input
                  type="text"
                  className="my-[11px] h-[34px] rounded-[8px] border border-[#00ff8c] bg-[#000] bg-opacity-30 pl-3"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              ) : (
                <div className="my-[11px] h-[34px]">
                  <ProgressSlider
                    progressChanging={(v) => {
                      setPercentage(v);
                    }}
                    initialValue={percentage}
                  />
                </div>
              )}
              <div className="flex justify-end">
                <div className="flex flex-col">
                  {!sliderOn ? (
                    <div
                      className="mb-3 cursor-pointer"
                      onClick={() => {
                        setSliderOn(true);
                      }}
                    >
                      <span className="text-[12px] text-[#00ff91]">
                        <span className="border-b border-[#00ff91]">
                          Use percent slider
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div
                      className="mb-3 cursor-pointer"
                      onClick={() => {
                        setSliderOn(false);
                      }}
                    >
                      <span className="text-[12px] text-[#00ff91]">
                        <span className="border-b border-[#00ff91]">
                          Enter exact amount
                        </span>
                      </span>
                    </div>
                  )}
                  <div className="rounded-[8px] bg-[#000000] bg-opacity-30 px-4 py-2 text-[12px] md:w-[284px]">
                    <div className="flex justify-between py-[3px]">
                      <label className="text-[#56B0B9]">Wallet balance</label>
                      <span className="text-white">20,000 $KATT</span>
                    </div>
                    <div className="flex justify-between py-[3px]">
                      <label className="text-[#56B0B9]">% committed</label>
                      <span className="text-[#FF8A00]">
                        {percentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="flex justify-between py-[3px]">
                      <label className="text-[#56B0B9]">Total committed</label>
                      <span className="text-white">5,000 $KATT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-auto">
              <div className="flex justify-between items-center rounded-[8px] bg-[#000] bg-opacity-30 py-3 pl-3 pr-4">
                <span className="text-[16px]">
                  I understand the tokens I commit to burn from this wallet
                  cannot be recovered.
                </span>

                {/* CheckBox show */}
                {!checkOn ? (
                  <div
                    onClick={() => {
                      setCheckOn(true);
                    }}
                  >
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/check-off.svg`}
                        alt="checkoff"
                      />
                    </span>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setCheckOn(false);
                    }}
                  >
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/icons/check-on.svg`}
                        alt="checkon"
                      />
                    </span>
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  width="fit"
                  label="Next"
                  onClick={() => {
                    navigation("/takeover/burn/set_burn_thresholds");
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </Board>
      </div>
    </div>
  );
};

export default StartBurnPile;
