import React from "react";

const Footer = () => {
  return (
    <div className="h-26 relative bottom-0 mt-5 w-full border-t border-[#91a0a1] py-8 text-center">
      <span className="text-[#00FF8C]">Copyright &copy; 2024 </span>
    </div>
  );
};

export default Footer;
