import React from "react";
import { useProjects } from "../../hooks/useProjects";

const TokenList = ({ setSelectedProjectIndex, selectedProjectIndex }) => {
  const { data: projects, isLoading, isError } = useProjects();
  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error loading projects.</p>;
  return (
    <div className="mx-auto flex w-full max-w-[1358px] flex-wrap justify-start gap-x-14 px-[48px]">
      {projects.map((project, index) => (
        <img
          key={index}
          onClick={() => {
            setSelectedProjectIndex(index);
          }}
          src={`${process.env.REACT_APP_BACKEND_URL}/images/avatars/${project.avatar}`}
          alt={project.projectName}
          className={`w-16 h-16 rounded-full mb-4 hover:opacity-40 transition-all cursor-pointer border-4 ${
            selectedProjectIndex === index
              ? "border-green-600"
              : "border-[#00000000] drop-shadow-lg shadow-[#96FFCF] "
          }`}
        />
      ))}
    </div>
  );
};

export default TokenList;
