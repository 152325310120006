import React from "react";
import Board from "../../../components/Board";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SendCode = () => {
  const navigation = useNavigate();

  // change this value of variable to your uniquecode 
  const unixCode = '0XM9'

  // this functions is handling fetching data from twitter to send DM
  const handleSendButton = async () => {
    const body = {
      message: `I am the project owner for and my secret code is ${unixCode}.`,
      participanId: process.env.REACT_APP_TWITTER_ACCOUNTID
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/twitter/message`, body);
      console.log(response);
      
      navigation("/takeover/verify_project/confirm_setup");
    } catch (error) {
      alert('Failed Send Auto DM')
    }
  }
  return (
    <div className="md:w-[60%] sm:w-8/12 w-10/12 flex flex-col gap-6">
      <div className="flex bg-[#F83737] bg-opacity-[32%] rounded-lg text-center p-4 md:mb-2 mb-0 md:mt-0 mt-6">
        <span className="text-[#FFD3D3] text-[18px] leading-8 tracking-tighter">
          This project is not yet live, some information or verification is
          missing, see below for details.{" "}
        </span>
      </div>
      <Board>
        <div className="flex flex-col gap-4 p-6">
          <div className="flex">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/verify.svg`}
              alt="solar_fire-bold"
            />
            <span className="pl-4 text-[22px]">Verify project ownership</span>
          </div>
          <span className="text-[16px] leading-6 tracking-tighter">
            Send the below code in a DM to <b>@tokentakeover</b> on Twitter from
            the project’s official Twitter page including the project title as
            entered into Token Takeover.
          </span>
          <div className="flex flex-col gap-4 p-2">
            <div className="flex justify-center gap-4">
              {unixCode.split('').map((item) => (
                <div className="bg-[#000] bg-opacity-30 rounded-lg lg:px-6 sm:px-4 px-2 lg:py-8 sm:py-4 py-2">
                <span className="font-bold lg:text-[64px] text-[48px] leading-[50px] tracking-tighter">
                  {item}
                </span>
              </div>
              ))}
            </div>
            <div className="flex flex-col gap-3">
              <span className="font-bold text-[16px] leading-6 tracking-tighter text-center">
                Already logged in to the official project twitter? Use the
                button below to send the DM.
              </span>
              <div className="flex justify-center">
                <Button
                  label="Send Auto DM"
                  width="fit"
                  onClick={() => handleSendButton(unixCode)}
                />
              </div>
            </div>
          </div>
        </div>
      </Board>
      <div className="flex items-center bg-[#00FF91] bg-opacity-30 rounded-lg p-6">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/set_project.svg`}
          alt="set_project"
        />
        <span className="pl-4 font-bold text-[22px]">Metadata complete</span>
      </div>
    </div>
  );
};

export default SendCode;
