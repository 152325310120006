import React from "react";
import Board from "../../components/Board";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/useStore";
import { toast } from "react-toastify";

const truncateAddress = (address, startChars = 10, endChars = 5) => {
  if (!address) return "";
  return `${address.slice(0, startChars)} ... ${address.slice(-endChars)}`;
};

const Sidebar = ({ selectedProject }) => {
  const joinedState = useStore((state) => state.isJoined);

  const navigation = useNavigate();
  return (
    <div className="mb-6 flex w-11/12 flex-col gap-6 md:mb-0 md:w-[35%]">
      <Board className="flex flex-col items-center py-3 sm:py-5">
        <div className="flex w-full justify-start px-3 sm:px-5">
          <div className="flex w-40 items-center justify-start gap-1 rounded-full bg-gradient-to-r from-[#00FF8C6a] to-[#ffffff01] px-2 py-1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/2568 members.svg`}
              alt="Main Token"
              className="h-5 w-4 rounded-full"
            />
            <span>
              {selectedProject.joinedMembers.length.toLocaleString()} members
            </span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 p-4 sm:p-8 md:p-12">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/images/avatars/${selectedProject.avatar}`}
            className="h-60 w-60 rounded-full"
            alt="Selected Project Avatar"
          />
          <span className="text-2xl font-bold">
            {selectedProject.projectName}
          </span>
          <p className="text-sm">{selectedProject.description}</p>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
              alt="X"
              className="h-6 w-6"
            />
            <span>
              <a
                href={selectedProject.projectSocials.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                @{selectedProject.projectSocials.twitter.split("/").pop()}
              </a>
            </span>
          </div>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/telegram.svg`}
              alt="X"
              className="h-6 w-6"
            />
            <span>
              <a
                href={selectedProject.projectSocials.telegram}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {selectedProject.projectSocials.telegram.split("/").pop()}
              </a>
            </span>
          </div>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/Website.svg`}
              alt="X"
              className="h-6 w-6"
            />
            <span>
              <a
                href={selectedProject.officialWebsite}
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
              >
                {selectedProject.officialWebsite.split("/").pop()}
              </a>
            </span>
          </div>
          <Button
            width="full"
            className={
              joinedState
                ? "bg-[#0D1F21] border-[#00FF91] border hover:opacity-100"
                : ""
            }
            text={joinedState ? "[#00FF91]" : undefined}
            onClick={() => {
              if (!joinedState) {
                navigation(`/details/${selectedProject._id}/connect_wallet`);
              }
            }}
            label={
              <span className="flex gap-3 uppercase">
                <img
                  src={
                    joinedState
                      ? `${process.env.PUBLIC_URL}/assets/icons/Checked.svg`
                      : `${process.env.PUBLIC_URL}/assets/icons/solar_add-square-linear.svg`
                  }
                  alt="X"
                  className="h-6 w-6"
                />
                {joinedState
                  ? truncateAddress(selectedProject.tokenAddress)
                  : "Join Takeover"}
              </span>
            }
          />
        </div>
      </Board>
      <Board className="flex flex-col justify-between p-6 sm:flex-row lg:p-10">
        <div className="flex flex-row items-center justify-center gap-6 sm:flex-col sm:gap-2">
          <span className="text-xs text-[#56B0B9]">current price</span>
          <span className="font-bold">$0.00025</span>
        </div>
        <div className="flex flex-row items-center justify-center gap-6 sm:flex-col sm:gap-2">
          <span className="text-xs text-[#56B0B9]">market cap</span>
          <span className="font-bold">$24.524M</span>
        </div>
        <div className="flex flex-row items-center justify-center gap-6 sm:flex-col sm:gap-2">
          <span className="text-xs text-[#56B0B9]">24H volume</span>
          <span className="font-bold">$35,354</span>
        </div>
      </Board>
    </div>
  );
};

export default Sidebar;
