import { useState, useEffect } from "react";
import axios from "axios";

export const useWalletTokenBalance = (address, chain, token_address) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(null);

  useEffect(() => {
    const fetchTokenBalances = async () => {
      try {
        const response = await axios.get(
          `https://deep-index.moralis.io/api/v2.2/${address}/erc20`,
          {
            headers: {
              accept: "application/json",
              "X-API-Key": process.env.REACT_APP_MORALIS_API_KEY,
            },
            params: {
              chain,
              token_addresses: [token_address],
            },
          }
        );

        setData(response.data);
      } catch (e) {
        console.log(e);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchTokenBalances();
  }, [address, chain, token_address]);

  return { data, isLoading, isError };
};
