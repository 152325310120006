import * as React from "react";
import { useAccount, useConnect } from "wagmi";
import wallets from "./Wallets.json";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useMemberByAddress } from "./hooks/useMemberByAddress";
import axiosInstance from "./utils/axiosInstance";
import { toast } from "react-toastify";
import useStore from "./store/useStore";

export function WalletOptions({ nextUrl = null }) {
  const updateProjectInfoField = useStore(
    (state) => state.updateProjectInfoField
  );

  const { address, isConnected, chainId } = useAccount();
  const { connectors, connect } = useConnect();

  const navigate = useNavigate();
  const { data: member } = useMemberByAddress({ address });

  const CreateMemberMutation = useMutation({
    mutationFn: async ({ address }) => {
      const response = await axiosInstance.post(`/members`, {
        address: address,
      });
      return response.data;
    },
    onSuccess: (data) => {
      toast.success(data.message);
      updateProjectInfoField("owner", data.member._id);
      updateProjectInfoField("chainId", chainId);
      if (nextUrl) navigate(nextUrl);
    },
    onError: (error) => {
      toast.error("Creating failed.");
    },
  });

  React.useEffect(() => {
    if (isConnected) {
      if (member && member.members !== undefined) {
        if (member.members.length === 0) {
          CreateMemberMutation.mutate({
            address,
          });
        } else {
          updateProjectInfoField("owner", member.members[0]._id);
          updateProjectInfoField("chainId", chainId);
          if (nextUrl) navigate(nextUrl);
        }
      } else {
        CreateMemberMutation.mutate({
          address,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return connectors.map((connector) => (
    <WalletOption
      key={connector.uid}
      connector={connector}
      onClick={async () => {
        await connect({ connector });
        // setselectedConnector(connector);
      }}
    />
  ));
}

function WalletOption({ connector, onClick }) {
  // const [ready, setReady] = React.useState(false);

  // React.useEffect(() => {
  //   (async () => {
  //     // const provider = await connector.getProvider();
  //     // setReady(!!provider);
  //   })();
  // }, [connector]);

  return wallets.map((wallet, index) => {
    if (wallet.id === connector.id)
      return (
        <div
          key={wallet.id}
          className="flex w-[45%] cursor-pointer flex-wrap items-center justify-between rounded-[8px] bg-[#000] bg-opacity-10 px-3 py-1.5 hover:bg-opacity-20 lg:w-[35%]"
          onClick={onClick}
        >
          <span>{connector.name}</span>
          <div className="flex gap-5">
            <img
              className="rounded"
              src={`${process.env.PUBLIC_URL}${wallet.icon}`}
              alt={`${wallet.name}`}
            />
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`}
              alt="arrow-right"
            />
          </div>
        </div>
      );
    else return null;
    // else return (
    //   <div className="flex w-[45%] cursor-pointer flex-wrap items-center justify-between rounded-[8px] bg-[#000] bg-opacity-10 px-3 py-1.5 hover:bg-opacity-20 lg:w-[35%]" onClick={() => {
    //     window.location.href = "/takeover/select_token"
    //   }}>
    //     <span>{wallet.name}</span>
    //     <div className="flex gap-5">
    //       <img className="rounded" src={`${process.env.PUBLIC_URL}${wallet.icon}`} alt={`${wallet.name}`} />
    //       <img src={`${process.env.PUBLIC_URL}/assets/icons/arrow-right.svg`} alt="arrow-right" />
    //     </div>
    //   </div>
    // )
  });
}
