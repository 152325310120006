import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./Routes";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import HeaderAdmin from "./layouts/Header_admin";
import HeaderTakeover from "./layouts/Header_takeover";
import HeaderVerifyProject from "./layouts/Header_verifyproject";

import { config } from "./wagmiConfig";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (window.location.pathname.startsWith("/admin") && !token) {
      navigate("/admin");
    }
  }, [navigate]);

  const isAdminRoute =
    window.location.pathname.startsWith("/admin") &&
    window.location.pathname !== "/admin";

  const isTakeoverRoute = window.location.pathname.startsWith("/takeover");
  const isVerifyProjectRoute = window.location.pathname.startsWith(
    "/takeover/verify_project"
  );

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <div className="relative h-screen w-full overflow-y-auto">
          {isAdminRoute ? (
            <HeaderAdmin />
          ) : isVerifyProjectRoute ? (
            <HeaderVerifyProject />
          ) : isTakeoverRoute ? (
            <HeaderTakeover />
          ) : (
            window.location.pathname !== "/admin" && <Header />
          )}
          <Routes />
          {window.location.pathname.startsWith("/admin") ? null : <Footer />}
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
