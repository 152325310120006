import React from "react";
import projects from "../../../Projects.json";
import Board from "../../../components/Board";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigation = useNavigate();
  return (
    <div className="md:w-[35%] sm:w-8/12 w-10/12 flex flex-col gap-6">
      <Board className="flex flex-col items-center py-3 sm:py-5">
        <div className="flex justify-start w-full px-3 sm:px-5">
          <div className=" w-40 flex  justify-start gap-1 items-center bg-gradient-to-r from-[#00FF8C6a] to-[#ffffff01] rounded-full py-1 px-2">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/2568 members.svg`}
              alt="Main Token"
              className="w-4 h-5 rounded-full "
            />
            <span>{projects[0].members.toLocaleString()} members</span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 p-4 md:p-12 sm:p-8">
          <img
            src={`${process.env.PUBLIC_URL}${projects[0].img_url}`}
            className="rounded-full  w-60 h-60"
            alt="Selected Project Avatar"
          />
          <span className="text-2xl font-bold ">{projects[0].name}</span>
          <p className="text-sm">{projects[0].description}</p>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/twitter.svg`}
              alt="X"
              className="w-6 h-6"
            />
            <span>Official Twitter Page</span>
          </div>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/telegram.svg`}
              alt="X"
              className="w-6 h-6"
            />
            <span>Official Telegram Page</span>
          </div>
          <div className="flex w-full gap-3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/Website.svg`}
              alt="X"
              className="w-6 h-6"
            />
            <span>Official Project Website</span>
          </div>
          _________________should be Address at here__________
          <Button
            width="full"
            onClick={() => {
              navigation(`/details/${0}/connect_wallet`);
            }}
            label={
              <span className="flex gap-3">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/solar_add-square-linear.svg`}
                  alt="X"
                  className="w-6 h-6"
                />
                Join Takeover
              </span>
            }
          />
        </div>
      </Board>
      <Board className="flex flex-col justify-between p-4 sm:flex-row lg:p-10 md:p-6">
        <div className="flex flex-row items-center justify-center gap-6 sm:gap-2 sm:flex-col">
          <span className="text-[#56B0B9] text-xs">current price</span>
          <span className="font-bold">$0.00025</span>
        </div>
        <div className="flex flex-row items-center justify-center gap-6 sm:gap-2 sm:flex-col">
          <span className="text-[#56B0B9] text-xs">market cap</span>
          <span className="font-bold">$24.524M</span>
        </div>
        <div className="flex flex-row items-center justify-center gap-6 sm:gap-2 sm:flex-col">
          <span className="text-[#56B0B9] text-xs">24H volume</span>
          <span className="font-bold">$35,354</span>
        </div>
      </Board>
    </div>
  );
};

export default Sidebar;
