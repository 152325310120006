import React, { useEffect, useState } from "react";
import Board from "../../components/Board";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import axiosInstance from "../../utils/axiosInstance";

const Admin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    if (authToken && window.location.pathname !== "/admin/directory") {
      toast.success("Already Loggedin");
      navigate("/admin/directory");
    }
  }, []);

  const loginMutation = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.post("/auth/login", {
        username,
        password,
      });
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("authToken", data.token);
      toast.success("Login successful!");
      navigate("/admin/directory");
    },
    onError: (error) => {
      toast.error("Login failed. Please check your credentials.");
    },
  });

  const handleLogin = () => {
    if (username && password) {
      loginMutation.mutate();
    } else {
      toast.error("Please enter both username and password.");
    }
  };

  return (
    <div
      className="relative flex flex-col w-full h-screen text-white"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/auth_bg.png)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top left",
      }}
    >
      <div className="w-screen flex justify-center items-center py-[48px] px-[48px] absolute top-0 max-w-[1358px] right-1/2 translate-x-1/2">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/logo.svg`}
          alt="Group Icon"
          className="cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
      <div className="absolute flex items-center justify-center w-full mt-32 overflow-y-auto">
        <Board className="py-6 flex flex-col items-center gap-8 px-20 max-w-[560px] w-full">
          <span className="text-2xl font-bold">Admin Login</span>
          <div className="flex flex-col w-full gap-6">
            <div className="flex flex-col w-full gap-2">
              <span>Username</span>
              <input
                className="bg-[#111827] transition-all px-[13px] py-[9px] border border-[#047857] rounded-md"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <span>Password</span>
              <input
                className="bg-[#111827] transition-all px-[13px] py-[9px] border border-[#047857] rounded-md"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <Button label="Login" onClick={handleLogin} />
          </div>
        </Board>
      </div>
    </div>
  );
};

export default Admin;
